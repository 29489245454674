import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import './styles/styles.less';

import * as serviceWorker from './serviceWorker';
import DashboardWrapper from './DashboardWrapper';

const windowUrl = window.location.href;

if (windowUrl.includes('staging') || windowUrl.includes('localhost')) {
  ReactGA.initialize('UA-110662465-3');
} else {
  ReactGA.initialize('UA-110662465-2');
}

ReactDOM.render(
  <BrowserRouter>
    <DashboardWrapper />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
