import { transaction, decorate, action, observable } from 'mobx';
import _ from 'lodash';
import moment from 'moment';

import { CarStore } from 'stores';

import AppStore from './App';
import ShopStore from './ShopStore';
import CurrentUserStore from './CurrentUserStore';
import AbstractStore from './abstract/AbstractStore';
import WorkOrderObject from './Classes/WorkOrderObject';
import { webServiceProvider, download, print } from '../shared';
import TableStore from './abstract/TableStore';
import { errors } from 'helpers';
import { Logger } from './Classes';

const DIRECT_ENDPOINT = `${process.env.REACT_APP_API_URL}v1`;
// const DIRECT_ENDPOINT = 'http://localhost:3000';

class WorkOrderStore extends AbstractStore {
  workOrderAbortController = new AbortController();
  shopWorkOrdersAbortController = new AbortController();
  searchWorkOrdersAbortController = new AbortController();
  fetchWorkOrdersAbortController = new AbortController();
  workOrderLinesAbortController = new AbortController();
  partsAbortController = new AbortController();
  updatePartsAbortController = new AbortController();

  shouldReloadList = false;

  randomWorkOrderId = () => _.padStart(_.random(999999), '0');
  currentDateTime = moment();

  workOrderInitialValues = {
    woInvoiceNumber: this.randomWorkOrderId(),
    woCreatedBy: '',
    woSelectVehicle: undefined,
    woAssetType: 'Vehicle',
    woAssignedTo: '',
    woStatus: 'open',
    woRepairType: '',
    woRepairPriority: '',
    woIssueReportDate: this.currentDateTime,
    woStartDate: undefined,
    woCompletedDate: undefined,
    mileage: undefined,
    engineHours: undefined,
    tax: {
      type: 'percentage',
      value: 0,
    },
    lineItems: [],
  };

  workOrderBeingSaved = JSON.parse(JSON.stringify(this.workOrderInitialValues));

  key32ServiceTasks = [
    'Cab, Climate Control, Instrumentation, & Aerodynamic Devices Group',
    'Air Conditioning, Heating & Ventilating System',
    'Air Conditioning Assembly - Complete',
    'Heating & Ventilating',
    'Heating & Ventilating',
    'Auxiliary Heating and Cooling - Self-Powered',
    'Heater and A/C - Sleeper',
    'Auxiliary Fan - Defroster',
    'Heater and A/C - Combination Unit, Cab',
    'Cab & Sheet Metal',
    'Radiator Grille',
    'Fenders & Mud Flaps - Front Wheel',
    'Running Boards',
    'Hood',
    'Cowl Panels',
    'Roof Panel & Reinforcements',
    'Center Pillar & Reinforcements',
    'Instrument Panel',
    'Sun Visor',
    'Mirrors',
    'Cab Accessories - Interior',
    'Floor Pan',
    'Floor Coverings',
    'Panel Deadeners',
    'Quarter & Deck Panels - Rear',
    'Tailgate - Rear',
    'Cab Or Front Door Panels',
    'Cab Or Front Door Mechanism',
    'Side Door Panels & Ramps',
    'Side Door Mechanism',
    'Panel - Rear Door, Cab',
    'Rear Door Mechanism',
    'Handles - Inner & Outer',
    'Glass',
    'Glass Channels',
    'Weather Stripping, Gaskets & Bumpers',
    'Seat Frames & Springs',
    'Adjuster - Front Seat',
    'Trim - Seat',
    'Interior Moldings',
    'Body Moldings - Exterior',
    'Sidewall & Miscellaneous Trim',
    'Sheet Metal Ornamentation',
    'Exterior Body Ornamentation',
    'Windshield Wiper & Washer',
    'Tilting Mechanism',
    'Seats',
    'Deflector - Non-Aerodynamic',
    'Cab Guard Assembly',
    'Cab Entry Assists',
    'Guard - Overhead, Operator Protection',
    'Crosswalk (Deckplate) - Frame',
    'Cab',
    'Insulator - Engine Noise',
    'Side Door - Special Application',
    'Vent Assembly',
    'Upholstery Trim - Cab',
    'Sleeper',
    'Occupant Safety Devices',
    'Signage - Integral to Body',
    'Instruments, Gauges, Warning & Shutdown Devices, & Meters',
    'Gauges & Warning Devices',
    'Speedometer',
    'Meter Assembly',
    'Shutdown Devices',
    'Anti-Theft',
    'Monitoring Devices',
    'Aerodynamic Devices',
    'Deflector - Wind, Roof Mounted',
    'Fairing - Nose',
    'Fairing - Wind, Roof Mounted',
    'Fairing - Cab Extenders',
    'Fairing - Chassis',
    'Fairings - Sleeper',
    'Fairing - Sleeper Extender',
    'Fairing - Transitional',
    'Air Deflector',
    'Chassis Group',
    'Axles - Front',
    'Axle - Non-Driven, Front',
    'Axles - Rear',
    'Axle - Non-Driven, Rear',
    'Axle - Non-Driven, Tag/Pusher',
    'Brakes',
    'Front Brakes & Drums',
    'Rear Brakes & Drums',
    'Parking Brakes',
    'Spring Parking Brake (Separate Or Combination W/Service Brake)',
    'Pedal Assembly - Service Brake',
    'Brake Master Cylinder',
    'Brake Lines & Fittings - Hydraulic & Air',
    'Vacuum Type Power Brakes',
    'Air Compressor',
    'Air Type Power Brakes',
    'Antilock Sensor Assembly',
    'Electric Brakes',
    'Air Disc Brakes',
    'Tag/Pusher Axle Brakes',
    'Air Brake Monitoring System',
    'Hydraulic Motor Brake System',
    'Frame',
    'Frame Assembly',
    'Bumpers & Attaching Parts',
    'Engine Mountings',
    'Cab & Body Mountings',
    'Battery Support',
    'Fuel Tank Support',
    'License Carrier',
    'Attaching Devices - Hoses, Lines, Tubes & Wire',
    'Sleeper Mounting',
    'Toolbox Mounting',
    'Quarter and Full Fenders',
    'Jackstand Assembly',
    'Noise Shield',
    'Mud Flaps - Rear Wheel',
    'Lifting Devices',
    'Steering',
    'Steering Wheel',
    'Steering Shaft Support',
    'Steering Gear',
    'Steering Linkage',
    'Power Steering Pump',
    'Control Valve (Torque Generator) - Integral Gear Type',
    'Control Valve - Tie Rod Type',
    'Power Steering Pump Motor - Electric',
    'Dual Steering',
    'Steering - All Steer',
    'Steering Assembly - Transferable (Vario Pilot)',
    'Steering Stability System',
    'Steering Gear - Rack & Pinion Type',
    'Hydraulic Steering System',
    'Suspension',
    'Independent Front Suspension',
    'Front Springs',
    'Front Shock Absorbers',
    'Front Stabilizer',
    'Suspension - Rear (Non-Air)',
    'Shock Absorber - Rear',
    'Stabilizer - Rear',
    'Air Ride Suspension',
    'Air Leaf Suspension',
    'Liftable Air Suspension',
    'Cab/Sleeper Suspension',
    'Slider - Air Ride Suspension',
    'Locking Device - Ride Height',
    'Tires, Tubes, Liners & Valves',
    'Tire - Pneumatic',
    'Tire - Semi-Pneumatic',
    'Tire - Cushion',
    'Inner Tube - Pneumatic Tire',
    'Tire - Solid',
    'Liner (Flap) - Pneumatic Tire',
    'Valve Assembly',
    'Tire Pressure Inflation and Monitoring Systems',
    'Tire - Foam Filled',
    'Wheels, Rims, Hubs & Bearings',
    'Front Axle Hubs & Bearings',
    'Drive Axle Hubs, Bearings & Seals',
    'Wheels',
    'Trailer, Converter, Tag & Pusher Axle Hubs & Bearings',
    'Bearings - General',
    'Automatic/Manual Chassis Lubricator',
    'Reservoir & Lines',
    'Grease Pump - Automatic Lubricator',
    'Timer',
    'Controller',
    'Wiring - Solenoid',
    'Block Assembly - Grease',
    'Hose, Tubing & Connectors',
    'Undercarriage',
    'Track',
    'Roller',
    'Idler',
    'Tensioner',
    'Sprocket',
    'Shields and Guards - Undercarriage',
    'Equalizer - Undercarriage',
    'Pivot Shaft Mechanism',
    'Stabilization',
    'Feet / Pads',
    'Hydraulics - Stabilization',
    'Controls - Stabilization Unit',
    'Supports',
    'Locks',
    'Outrigger Assembly',
    'Drive Train Group',
    'Axles - Driven, Front Steering',
    'Axle Assembly, Driven - Front',
    'Axle Assemblies - Driven, Front Steering',
    'Portal Assembly - Front Driven Axle',
    'Axles - Driven, Rear',
    'Axle Housing, Rear',
    'Shaft - Rear Axle',
    'Differential Carrier',
    'Power Divider & Inter-Axle Differential',
    'Axle Controls - Air Or Vacuum',
    'Axle Controls - Electric Shift',
    'Axle Assembly - Driven, Rear',
    'Portal Assembly - Rear Driven Axle',
    'Clutch System',
    'Control Assembly - Mechanical, Clutch',
    'Clutch',
    'Clutch Output Shaft - Divorced Transmission',
    'Control Assembly - Electronic Automated Shift, Clutch',
    'Drive Shafts',
    'Drive Shaft - Front Driving Axles',
    'Drive Shaft, Behind Main Transmission',
    'Retarder - Drive/Propeller Shaft',
    'Chain Drive',
    'Transfer Case',
    'Transfer Case Assembly',
    'Accumulator - Transfer Case',
    'Annulus - Planetary Gear Carrier',
    'Transmission - Main, Manual',
    'Transmission Case',
    'Reverse, Regular Countershaft Gears & Bearings',
    'Maindrive & Main Shaft Bearings',
    'Retainer - Main Bearing',
    'Maindrive Gear',
    'Synchronizer & Gears - Main Shaft',
    'Countershaft - High Range',
    'Countershaft - Low Range',
    'Cover Assembly - Manual Transmission',
    'Rails, Forks & Internal Shift Mechanism',
    'Transmission External Control',
    'Transmission Assembly - Manual',
    'Pump - Oil',
    'Electronic Controls, Transmission, Manual',
    'Automated Transmission',
    'Transmission - Automatic',
    'Case - Automatic Transmission',
    'Converter',
    'Hydraulic Retarder Rotor',
    'Direct Drive Clutch Assembly',
    'High Range Clutch',
    'Forward Clutch Assembly',
    'Direct Clutch & Intermediate Sprag',
    'Intermediate Clutch & Center Support',
    'Low Range Clutch',
    'Reverse Clutch Assembly',
    'Splitter',
    'Housing Converter (Bell Housing)',
    'Planet Carrier &/Or Output Shaft Assembly',
    'Reaction Carrier & Low Sprag Assembly - Front',
    'Carrier Assembly - Rear Or Low, Output',
    'First Range Assembly',
    'Sun Gear & Shaft Assembly',
    'Reverse Range Assembly',
    'Main Shaft Assembly & Rear Internal Gear',
    'Bands',
    'Rear Servo Assembly',
    'Low Servo Assembly',
    'Front Servo Assembly (2nd Gear Overrun)',
    'Valve Body Assembly - Main Control',
    'Solenoid Assembly - Detent, Automatic Transmission',
    'Modulator Assembly',
    'Valve Body Assembly - Stator Control',
    'Solenoid Assembly - Variable Pitch Stator Control',
    'Oil Pump Assembly',
    'Rear Pump',
    'Rear Bearing Retainer Or Case Extension Assembly',
    'Parking Lock Or Brake & Actuator Assembly',
    'Range Selector Or Manual Linkage',
    'Governor Assembly - Automatic Transmission',
    'Output Drive Transfer Gearing',
    'Oil Pan & Screen Assembly',
    'Transmission Assembly - Automatic',
    'Electronic Controls, Transmission, Automatic',
    'Cooler - Manifold',
    'Auxiliary Transmission',
    'Case - Auxiliary Transmission',
    'Cover - Auxiliary Transmission',
    'Main Drive',
    'Main Shaft',
    'Countershaft',
    'Transmission - Auxiliary',
    'Auxiliary Section - Main Transmission, Manual',
    'Auxiliary Section - Main Transmission Assembly, Manual',
    'Cover - Rear Bearing',
    'Countershaft - Auxiliary Section',
    'Synchronizer Auxiliary Section',
    'Main Shaft Auxiliary Section',
    'Drive Gear - Auxiliary Section',
    'Intermediate Section Housing',
    'Air Cylinders',
    'Final Drive',
    'Electrical Group',
    'Charging System',
    'Generator/Alternator',
    'Regulator',
    'Battery Charger - Electrically Propelled Vehicles, On Board',
    'Cranking System',
    'Battery & Cables',
    'Starter',
    'Pre-Heater',
    'Starter - Air',
    'Battery Charger - On Board',
    'Emergency Start',
    'Ignition System',
    'Ignition Switch',
    'Distributor - Engine Assembly',
    'Coil & Spark Plugs',
    'Lighting System',
    'Head Lamps',
    'Lamps - Tail, Stop, Turn & License, Rear',
    'Signal, Parking, & Backup Lamps',
    'Bulbs, Fuses & Wiring - Clearance / Marker / Interior Lamps',
    'Electrical Grounds',
    'Warning & Emergency Lighting/Devices - Special Applications',
    'Signaling Devices',
    'Supplemental Information Devices',
    'Collision Avoidance System - Forward, Complete Assembly',
    'Lane Departure Warning System - Complete Assembly',
    'Visual Assist System - Complete Assembly',
    'Transponder',
    'Power Line Carrier (PLC), Data Communication',
    'Asset Tracking',
    'On Board Computer',
    'Surveillance System',
    'Modules/Relays - Electrical',
    'Modules - Electrical, Multiple Use',
    'Engine / Motor Systems Group',
    'Air Intake System',
    'Air Cleaner',
    'Intake Manifold',
    'Blower - Supercharger',
    'Intercooler',
    'Ether Injector Assembly',
    'Cooling System',
    'Shutters',
    'Radiator',
    'Fan - Engine Cooling',
    'Water Pump',
    'Water Manifold',
    'Thermostat - Engine Cooling System',
    'Coolant Filter',
    'Coolant Pre-Heater (On Power Plant) - External Electric Power Supply',
    'Coolant Pre-Heater (In Block) - External Electric Power Supply',
    'Coolant Pre-Heater - Self-Contained, Liquid (On Vehicle)',
    'Water Heater',
    'Coolant Diverter',
    'Exhaust System',
    'Emission Controls',
    'Exhaust Manifold',
    'Muffler Assembly',
    'Turbocharger Assembly',
    'Exhaust Brake',
    'Exhaust After-Treatment Devices',
    'Selective Catalytic Reduction System (SCR) ',
    'Fuel System',
    'Tank - Fuel, Liquid',
    'Filters',
    'Fuel Pump',
    'Injectors - Mechanical',
    'Carburetor',
    'Governor - Gasoline Engines',
    'Throttle Controls',
    'Engine Shutdown',
    'Heater - Fuel, Non-Electrical',
    'Converter - Propane / Natural Gas',
    'Speed Control System',
    'Tank - Fuel, Gaseous',
    'Injectors - Electronic',
    'Heater - Fuel, Electrical',
    'Power Plant',
    'Front Covers',
    'Cylinder Block & Crankcase',
    'Crankshaft',
    'Flywheel',
    'Crankshaft - Harmonic Balancer',
    'Connecting Rod, Piston & Rings Assembly',
    'Cylinder Head',
    'Camshaft - Accessory Drive & Valve Mechanism',
    'Oil Pump & Distribution System',
    'Oil Filler & Level Indicator',
    'Filter Assembly - Oil',
    'Regulator - Oil Temperature',
    'Oil Pan',
    'Ventilating System',
    'Retarder - Vehicle, Flywheel Type',
    'Pre-Heater - Oil (Power Plant)',
    'Auxiliary Reservoir - Oil',
    'Retarder - Engine, Valve Cycle Modification Type',
    'Overhaul Kit - Engine',
    'Engine Assembly',
    'Electronic Engine Controls',
    'Electric Propulsion System',
    'Motor - Electric Propulsion',
    'Controls - Electro-Mechanical, Electric Propulsion Motor',
    'Battery - Electric Propulsion Motor',
    'Controls - Electronic (Solid State), Electric Propulsion Motor',
    'Kit, Filter - Multi System',
    'Kit, Filter - Engine',
    'Power Train - Electric Hybrid',
    'Power Train Assemblies - Electric, Hybrid',
    'Power Train - Hydraulic, Hybrid',
    'Power Train Assemblies - Hydraulic, Hybrid',
    'Accessories Group',
    'General Accessories',
    'Tachometer',
    'Hubodometer',
    'Hourmeter',
    'Tachograph',
    'Recorder - Fuel & Mileage',
    'Accessory Safety Devices',
    'Fire Detection and Warning',
    'Navigation System - GPS',
    'Fare Box',
    'Speedograph',
    'Information Center',
    'Data Logger',
    'Air Pump - On Board',
    'Electrical Accessories',
    'Cigar Lighter',
    'Clock',
    'Radio Equipment',
    'Tape Player',
    'Transmitter - CB',
    'CD Player',
    'Television',
    'Refrigerator',
    'Speakers',
    'Fan - Auxiliary',
    'Auxiliary Power',
    'Cellular Telephone',
    'Intercom System',
    'Microwave Oven',
    'DVD Player',
    'Heater and Cooler - Thermoelectric, Portable',
    'Heater - Interior, Electric',
    'Heater - Lower Radiator Hose',
    'Expendable Items',
    'Tire Chains',
    'Sander',
    'Mattress',
    'Safety Equipment',
    'Markings - Vehicle',
    'Tools & Diagnostic Devices',
    'Hardware - Expendable Items',
    'Hoses & Tubings & Fittings',
    'Steel Stock',
    'Sanding/Grinding Supplies',
    'Supplies',
    'Aluminum Stock',
    'Wood',
    'Environmental',
    'Welding Supplies',
    'Bearing - General Use (Non-Vehicle) ',
    'Manual',
    'Paint & Preservatives - Vehicle',
    'Vehicle Fluids, Lubricants, Gases, & Particulates',
    'Horns & Mountings & Reverse Signal Alarms',
    'Horn & Mounting',
    'Alarm',
    'Cargo Handling, Restraints, & Lift Systems',
    'Platform - Power Liftgate',
    'Vertical Slides - Power Tailgate',
    'Ram - Power Tailgate',
    'Dump Trailer Hydraulic Lift Mechanism',
    'Conveyor - Retractable Roller (Hydraulic)',
    'Unloaders - Electric, Containers & Pallets',
    'Mast Assembly - Lift Truck',
    'Carriage Assembly - Lift Truck',
    'Carriage & Lift Assembly - Lift Truck',
    'Hydraulic System - Lifts & Attachments',
    'Carriage Assembly Attachments',
    'Cargo Restraints',
    'Counterweight',
    'Hydraulic Lift System Pump Motor - Electric',
    'Pump Unit - Electro-Hydraulic',
    'Mast Assembly - Power Tailgate',
    'Steering Head',
    'Scissors Assembly',
    'Conveyor - Retractable Roller (Air)',
    'Control Panel - Retractable Roller (Air)',
    'Control System - Conveyor, Retractable Roller (Air)',
    'Shelving and Supports',
    'Wheelchair Lift',
    'Pallet Jack',
    'Hand Truck',
    'Drum - Hand Truck',
    'Jack - Ground Support Equipment (GSE)',
    'Power Take-Off',
    'Power Take-Off (PTO)',
    'Shift',
    'Powershift PTO',
    'Spare Wheel Mounting',
    'Spare Wheel Mounting Assembly',
    'Winch System',
    'Winch',
    'Vehicle Coupling System',
    'Fifth Wheel - Lower Coupler Assembly',
    'Pintle Hook Assembly',
    'Tow Hooks',
    'Draw Bar',
    'Coupler System',
    'Towing Hitch',
    'Lunette Assembly',
    'Tow Ring Assembly',
    'Towing Shackle Assembly',
    'Tow Bar Components',
    'Hydraulic System - Vehicle Coupling System',
    'Reservoir Assembly - Hydraulic System',
    'Pump Assembly - Hydraulic, Vane Type',
    'Pump Assembly - Hydraulic, Gear Type',
    'Pump Assembly - Hydraulic, Rotary Type',
    'Pump Assembly - Hydraulic, Gerotor Type',
    'Pump Assembly - Hydraulic, Piston Type',
    'Pump Assembly - Hydraulic, Centrifugal (Low Pressure)',
    'Pump Assembly - Hydraulic, Reciprocating (Air-Operated)',
    'Pump Assembly - Hydraulic, Manual',
    'Control Valve Assembly - Hydraulic System',
    'Relief Valve Assembly - Hydraulic System',
    'Check Valve Assembly - Hydraulic System',
    'Flow Control Valve Assembly',
    'Fittings - Hydraulic System, Vehicle Coupling',
    'Line Assembly - Hydraulic System',
    'Cylinder Assembly - Hydraulic System',
    'Filtration System - Fluid, Hydraulic System',
    'Cooler Assembly - Hydraulic System',
    'Articulation Hydraulics - On Road',
    'Combination Tag Axle/Converter Dolly',
    'Steering Hitch System',
    'Auxiliary Power Unit (APU)',
    'Auxiliary Power Unit (APU) Assembly',
    'Heating - APU',
    'Cooling - APU',
    'Support - APU',
    'Controls - Auxiliary Power Unit (APU)',
    'Inverter - APU',
    'Onboard Lavatory',
    'Toilet Assembly - Complete',
    'Sink Assembly - Complete',
    'Frame/Structure - Lavatory',
    'Stationary Generator',
    'Stationary Generator - Complete',
    'Alternator (AC) Assembly - Stationary Generator',
    'Auxiliary Receptacle - 120 Volt',
    'Circuit Breaker',
    'Control Panel',
    'Enclosure',
    'Battery Charger',
    'Sweeping',
    'Broom Assembly',
    'Wearable Items - Sweeping Unit',
    'Hydraulic System - Sweeping',
    'Support - Sweeper',
    'Control - Sweeping',
    'Spreading',
    'Spreader Assembly',
    'Wearable Items - Spreading Unit',
    'Hydraulic System - Spreading',
    'Support - Spreader',
    'Control - Spreading',
    'Electrical',
    'Chipping',
    'Chipper Assembly',
    'Wearable Items - Chipping Unit',
    'Hydraulic System - Chipping',
    'Support - Chipper',
    'Control - Chipping',
    'Blowing',
    'Blower Assembly',
    'Wearable Items - Blowing Unit',
    'Hydraulic System - Blowing',
    'Support - Blower',
    'Control - Blowing',
    'Vacuuming',
    'Vacuum Assembly',
    'Wearable Items - Vacuum Unit',
    'Hydraulic System - Vacuuming',
    'Support - Vacuum',
    'Control - Vacuuming',
    'Trenching',
    'Trencher Assembly',
    'Wearable Items - Trenching Unit',
    'Hydraulic System - Trenching',
    'Support - Trencher',
    'Control - Trenching',
    'Tilling',
    'Tiller Assembly',
    'Wearable Items - Tilling Unit',
    'Hydraulic System - Tilling',
    'Support - Tiller',
    'Control - Tilling',
    'Mowing',
    'Mower Assembly',
    'Wearable Items - Mowing Unit',
    'Hydraulic System - Mowing',
    'Support - Mower',
    'Control - Mowing',
    'Ripping',
    'Ripper Assembly',
    'Wearable Items - Ripping Unit',
    'Hydraulic System - Ripping',
    'Support - Ripper',
    'Control - Ripping',
    'Satellite Communications System',
    'Equipment Dependent Attachments Group',
    'Terminal Equipment - Multi Applications',
    'Antenna - RF System',
    'Antenna - Loran System',
    'Transceiver - Satellite Communications System',
    'Display/Keyboard',
    'Transceiver/Antenna System',
    'Load Tracking',
    'Digital Audio Receiver',
    'Hydraulic Systems - Multi-Function',
    'Hydraulic Systems Assembly',
    'Accumulator - Hydraulic System',
    'Cooler - Oil (Heat Exchanger)',
    'Cylinder - Hydraulic System',
    'Drive Assembly  For Motor Or Pump',
    'Filters, Strainers, Screens & Housing',
    'Hoses & Fittings',
    'Hose',
    'Manifold - Rotary (Swivel)',
    'Gear Motor',
    'Motor - Piston Type',
    'Pump - Centrifugal (Low Pressure Type)',
    'Pump - Gear Type',
    'Pump - Gearotor Type',
    'Pump - Variable Displacement Type',
    'Pump - Reciprocating (Diaphragm Type)',
    'Reservoir - Hydraulic System',
    'Bleeder Valve',
    'Check Valve  Assembly Components',
    'Control Valve - Hydraulic System',
    'Counter Balance Valve',
    'Flow Control Valve - Multi-Function, Hydraulic System',
    'Holding Valve',
    'Pilot Valve - Hydraulic System',
    'Pressure Bypass Valve',
    'Regulator Valve',
    'Relief Valve - Hydraulic System',
    'Warning Devices - Hydraulic. Sys.',
    'Motor - Hydraulic, Propulsion',
    'Scraping',
    'Blade Assembly',
    'Wearable Items - Scraping Unit',
    'Hydraulic System - Scraping',
    'Support - Scraper',
    'Control - Scraper',
    'Buckets',
    'Bucket Assembly - Bucket Equipment',
    'Wearable Items - Buckets',
    'Hydraulic System - Buckets',
    'Support - Bucket',
    'Control - Bucket',
    'Cutting Edge',
    'Teeth',
    'Lifting / Unloading',
    'Boom',
    'Wearable Items - Lifting/Unloading Units',
    'Hydraulic System - Lifting/Unloading',
    'Unloaders',
    'Control - Lifting/Unloading',
    'Carriage Assembly - Lift Trucks',
    'Springs - Unloader',
    'Conveyance',
    'Roller Assembly - Conveyance',
    'Wearable Items - Ripping Unit',
    'Hydraulic System - Conveyance',
    'Support - Conveyance',
    'Control - Conveyance',
    'Raking',
    'Rake Assembly',
    'Wearable Items - Raking Unit',
    'Hydraulic System - Raking',
    'Support - Raker',
    'Control - Rake Assembly',
    'Breaking',
    'Breaker Assembly',
    'Wearable Items - Breaking Unit',
    'Hydraulic System - Breaking',
    'Support - Breaker',
    'Control - Breaker Assembly',
    'Hammering',
    'Hammer Assembly',
    'Wearable Items - Hammering Unit',
    'Hydraulic System - Hammering',
    'Support - Hammer',
    'Control - Hammer Assembly',
    'Grappling',
    'Grapple Assembly',
    'Wearable Items - Grappling Unit',
    'Hydraulic System - Grappling',
    'Support - Grappler',
    'Control - Grapple Assembly',
    'Magnets',
    'Magnet Assembly',
    'Wearable Items - Magnet Unit',
    'Hydraulic System - Magnets',
    'Support - Magnet',
    'Control - Magnet Assembly',
    'Drilling and Boring System',
    'Boom Structure - Drilling and Boring',
    'Feeding Assembly',
    'Hydraulic System - Drilling and Boring',
    'Support - Driller/Borer',
    'Control - Drilling and Boring',
    'Frame - Drilling and Boring Unit',
    'Motor',
    'Rod Holder',
    'Flushing Assembly',
    'Hammer',
    'Auger',
    'Cable and Winch',
    'Personnel Bucket - Platform',
    'Pedestal - Fixed, Drilling and Boring Unit',
    'Pedestal - Rotating, Drilling and Boring Unit',
    'Shields and Guards - Drilling and Boring',
    'Pole Guide',
    'Cables and Pulleys (Sheaves), Drilling and Boring',
    'Pulling',
    'Hoist Drum',
    'Cables and Pulleys (Sheaves), Pulling',
    'Hydraulic System - Pulling',
    'Support - Puller',
    'Control - Pulling',
    'Frame - Pulling Unit',
    'Winches',
    'Personnel Bucket',
    'Boom Structure - Pulling',
    'Pedestal - Fixed, Pulling Device',
    'Pedestal - Rotating, Pulling Device',
    'Knuckle (Pivot Point)',
    'Sprockets',
    'Leveling Assembly',
    'Shields and Guards - Pulling Device',
    'Dust and Debris Collecting',
    'Holding Container',
    'Filtration - Dust and Debris Collection System',
    'Hydraulic System - Dust and Debris Collection',
    'Support - Dust/Debris Collector',
    'Control - Dust and Debris Collecting',
    'Frame - Dust and Debris Collection Unit',
    'Vacuum Device',
    'Pipe / Duct',
    'Brushes / Brooms',
    'Feeder',
    'Dust Suppression',
    'Motors - Dust and Debris Collecting',
    'Conveyors',
    'Air Compressor System',
    'Compressor - Air, Portable Unit',
    'Motor - Air Compressor System',
    'Hydraulic System - Air Compressor',
    'Support - Air Compressor',
    'Control - Air Compressor, Portable',
    'Frame - Air Compressor Unit, Portable',
    'Reservoir - Air Compressor System',
    'Plumbing',
    'Filtration - Air Compressor System, Portable',
    'Couplers',
    'Lines and Hoses',
    'Safety Devices - Air Compressor System',
    'Weighing & Measuring',
    'Scales Carriage',
    'Indicator',
    'Printer',
    'ECU',
    'Scale - Cargo, Suspension Mounted',
    'Deicing',
    'Deicing Assembly',
    'Wearable Items - Deicing Unit',
    'Hydraulic System - Deicing',
    'Support - Deicer',
    'Control - Deicing',
    'Paving',
    'Paver/Melter Assembly',
    'Wearable Items - Paving Unit',
    'Hydraulic System - Paving',
    'Support - Paver',
    'Control - Paving',
    'Compressor Assembly - Paving',
    'Pump/Agitator Assembly',
    'Boom Assembly - Paving',
    'Tank Assembly - Paving',
    'Painting & Spraying',
    'Painting/Spraying Assembly',
    'Wearable Items - Painting & Spraying Unit',
    'Hydraulic System - Painting & Spraying',
    'Support - Painting & Spraying',
    'Control - Painting & Spraying',
    'Compressor Assembly - Painting & Spraying',
    'Hot Water Pump Assembly',
    'Boom Assembly - Painting & Spraying',
    'Tank Assembly - Painting & Spraying',
    'Frame Assembly - Painting & Spraying Unit',
    'Burner Assembly',
    'Paint Heating Pump Assembly',
    'Tire Disinfectant Spray System',
    'Sawing',
    'Sawing Assembly',
    'Wearable Items - Sawing Unit',
    'Hydraulic System - Sawing',
    'Support - Sawing',
    'Control - Sawing',
    'Frame - Sawing Unit',
    'Rolling',
    'Rolling Assembly',
    'Wearable Items - Rolling Unit',
    'Hydraulic',
    'Support',
    'Control',
    'Frame',
    'Steering',
    'Vibration System',
    'Water System',
    'Bodies & Vessels Group',
    'Body',
    'Floor Assembly',
    'Front Wall &/Or Headboard Assembly',
    'Side Wall Assembly',
    'Roof Assembly & Skylight',
    'Side Door Assembly - Hinged',
    'Bolster & Hold-Down',
    'Lining - Plywood',
    'Lining - Flat Fiberglass',
    'Lining - Molded Fiberglass',
    'Dividers',
    'Scuff Liner',
    'Sealing',
    'Lining - Seal Coated Plywood',
    'Screws - All',
    'Rivets - All',
    'Tappet - Nylon, All',
    'Insulation - Body',
    'Flooring Assembly - Removable',
    'Bulkhead',
    'Body /Container Assembly Complete',
    'Ladder Rack',
    'Body Assembly - Soft Side',
    'Lining - Puncture Guard',
    'Rear Wall & Door',
    'Rear Wall & Bumper Assembly',
    'Header & Rail Assembly',
    'Door Assembly - Hinged, Rear',
    'Door Assembly - Roll-Up, Rear',
    'Chain Gate & Curtain Assembly',
    'Door Security Seal',
    'Shell - Tank Vessel, Inner',
    'Stiffeners',
    'Heads',
    'Slope Sheets',
    'Hopper (Cones)',
    'Sumps',
    'Jacket - Tank Vessel, Outer',
    'Outer Jacket',
    'Manholes',
    'Fill Lid',
    'Manhole Cover',
    'Manhole Collar',
    'Clamp - Hold-Down, Manhole Cover',
    'Hinge - Manhole Cover',
    'Rings & Bolsters',
    'Bolster',
    'Rings',
    'Trailer Frame & Support',
    'Support - Frame Assembly',
    'Leg Assembly',
    'Frame Assembly - Front',
    'Coupler Assembly - Upper',
    'Sub-Frame - Upper',
    'Sub-Frame - Lower',
    'Slider Mechanism',
    'Spring Hangers',
    'Dolly',
    'Framing - Platform Chassis Assembly',
    'Trim & Miscellaneous Hardware',
    'Fender Assembly - Body, Front',
    'Fender Assembly - Rear',
    'Carrier - Hose',
    'Meter Assembly',
    'Tire Carrier',
    'Deflector Assembly - (Mud Flaps)',
    'Tie-Down - Tarpaulin',
    'Tarpaulin Basket',
    'Tarpaulin',
    'Tarpaulin Roller Assembly',
    'Rack - Containers, Liquid Or Gas',
    'Air Duct - Reefer',
    'Shelf - Trailer',
    'Ladders - Trailers',
    'Walkway - Trailer',
    'Side Skirt - Trailer',
    'Extender - Aerodynamic, Trailer, Rear',
    'Trailer Safety Devices',
    'Pole - Anti-Nose Dive',
    'Monitor Assembly - Rear',
    'Mixers',
    'Drum',
    'Drive Assembly',
    'Hydraulic System - Mixers',
    'Support - Mixer',
    'Control - Mixer',
    'Frame - Mixer Unit',
    'Water Supply - Mixer',
    'Booster Axle',
    'Chutes',
    'Compaction Bodies',
    'Container Body',
    'Compaction Assembly',
    'Hydraulics - Compaction Body',
    'Support - Compacter',
    'Controls - Compaction Unit',
    'Frame - Compaction Body Unit',
    'Loader Assembly',
    'Ejection Assembly',
    'Trim and Miscellaneous Parts',
    'Tilt Bodies',
    'Body or Bed',
    'Boom Assembly',
    'Hydraulics - Tilt Bodies',
    'Support - Tilt Body',
    'Controls - Tilt Body Unit',
    'Frame - Tilt Body Unit',
    'Winches and Cables',
    'Tarp Assembly',
    'Rollers - Tilt Body',
    'Platform - Tilt Body',
    'Tailgate',
    'Bus Body',
    'Bus Body Assembly',
    'Bus Entry Step Components',
    'Bus Underbody Storage Compartment Components',
    'Bus Underbody Battery Compartment Components',
    'Bus Standard Side Window Components',
    'Bus Push-Out Side Window Components',
    'Bus Rear Window Components',
    'Bus Visibility Window Components',
    'Bus Driver Sliding Window Components',
    'Folding Bus Entry Door Components',
    'Outward Opening Bus Entry Door Components',
    'Bus Side Emergency Door Components',
    'Bus Rear Emergency Door Components',
    'Bus Wheelchair Access Door Components',
    'Bus Roof Emergency Escape Hatch Components',
    'Bus Roof Powered Vent Components',
    'School Bus Stop Arm Components',
    'Bus Barrier Components',
    'Bus Driver Sun Visor Components',
    'Bus Driver Control Console Components',
    'Bus Interior Luggage Rack Components',
    'Bus Seat Components',
    'Miscellaneous Bus Body Accessories',
    'Bus Body Electrical Components',
    'School Bus Crossing Gate Components',
    'Bus Passenger Heater Components',
    'Bus Entrance Step Heater Components',
    'Bus Body A/C System Components',
    'Bus Davenport Components',
    'Bus Under Passenger Seat Storage Box Components',
    'Bus Under Driver Seat Storage Box Components',
    'Bus Body Wheelchair Lift Components',
    'Articulated Joint Assembly',
    'Seat Belt Assembly',
    'Bulkhead Assembly - Bus Body',
    'Aisle Gate Assembly',
    'Markings - Bus Body',
    'Heating & Refrigeration Group',
    'Heating Unit',
    'Coils - Heater',
    'Heating Unit - Open Flame, Cargo',
    'Heating Unit - Catalytic',
    'Mechanical Refrigeration Unit - (MRU)',
    'Evaporator Assembly',
    'Compressor - Mechanical Refrigeration Unit (MRU)',
    'Compressor Assembly - Hermetic Type, (MRU)',
    'Compressor Assembly - Open Type, (MRU)',
    'Thermostat Assembly',
    'Controls - (MRU)',
    'Coupling/Cooling Linkage',
    'Condenser Assembly - Mechanical Refrigeration Unit',
    'Receiver Assembly',
    'Drier Assembly - Liquid Line',
    'Defrosting Assembly - Heating',
    'Control Panel Assembly - (MRU)',
    'Three-Way Valve Assembly - Complete',
    'Clutch Assembly - Drive, (MRU)',
    'Stop Start Controller Assembly',
    'Refrigeration Unit Assembly - Mechanical (MRU)',
    'Tank - Receiver, Mechanical Refrigeration Unit',
    'Controller - Thermostat',
    'Expansion Valve - Mechanical Refrigeration System',
    'Receiver Valve',
    'Service Valve',
    'Three-Way Valve',
    'Throttling Valve',
    'Compressor Assembly - Semi-Hermetic, (MRU)',
    'Remote Status Assembly',
    'Nitrogen Refrigeration Unit',
    'Fill Box Assembly',
    'Container Assembly',
    'Control Unit/Box Assembly',
    'Lines (All)',
    'Refrigeration Unit Assembly - Nitrogen',
    'Hold Over Plate Refrigeration',
    'Hold Over Plate Assembly',
    'Console Panel Assembly',
    'Condensing Unit Assembly',
    'Bulk Product Transfer, Handling & Storage Systems Group',
    'Blowers, Conveyors & Vibrators',
    'Blower - Single Stage',
    'Blower - Two Stage',
    'Turbine Conveyor - Exhaust Driven',
    'Vibrator',
    'Compressor - Bulk Product Systems',
    'Compressor Assembly - Hermetic Type, Bulk Product Transfer',
    'Compressor Assembly - Open Type, Bulk Product Transfer',
    'Storage - Bulk Storage System',
    'Lines, Tubes, Hoses & Fittings - Bulk Product Transfer Systems',
    'Lines - Crossover',
    'Line - Product Loading',
    'Line - Unloading, Product (See 095-001 For Manifold)',
  ];

  selectedCarIssues = [];

  setSelectedCarIssues = (carIssues) => {
    this.selectedCarIssues = carIssues;
  };

  formatWorkOrderLines = (lines) => {
    return lines.map((workOrderLine) => {
      const updatingId = {};
      if (workOrderLine.id) {
        updatingId.id = workOrderLine.id;
      }
      return {
        ...updatingId,
        title: workOrderLine.title,
        description: workOrderLine.description,
        labour_cost: parseFloat(workOrderLine.laborTotal),
        parts_cost: parseFloat(workOrderLine.partsTotal),
        parts: workOrderLine.parts.map((p) => ({
          ...p,
          id_vehicle_part: p.id_vehicle_part,
          amount: p.amount ? parseInt(p.amount) : 0,
          cost: p.cost ? parseFloat(p.cost) : 0,
        })),
        labours: workOrderLine.labours.map((l) => ({
          ...l,
          id_labour: l.id_labour,
          hours: l.hours,
          cost: parseFloat(l.cost),
        })),
        services: workOrderLine.services.map((i) => ({
          ...i,
          issue: i.issue,
          id_vehicle_service: +i.id_vehicle_service,
        })),
      };
    });
  };

  fetchWorkOrder = async (workOrderId, fetchCars = true) => {
    try {
      if (!workOrderId) {
        throw new Error('Invalid Id');
      }
      const workOrder = await webServiceProvider.getById(
        'workOrder',
        workOrderId
      );
      const workOrderObject = new WorkOrderObject(workOrder);
      if (workOrderObject.id === undefined) {
        AppStore.addError('Unable to load workOrder');
        return;
      }

      this.data.set(Number(workOrderId), workOrderObject);

      if (fetchCars) {
        await workOrderObject.getCars({
          limit: workOrderObject.totalCarsAssociated,
        });
      }
    } catch (err) {
      errors.response(
        err,
        `Error fetching workOrder data with id ${workOrderId}`
      );
    }
  };

  getWorkOrderById = async (workOrderId) => {
    try {
      if (!workOrderId) {
        throw new Error('Invalid Id');
      }

      this.workOrderAbortController.abort();

      this.workOrderAbortController = new AbortController();
      this.workOrderSignal = this.workOrderAbortController.signal;

      const workOrder = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/work-order/${workOrderId}`,
        'GET',
        null,
        this.workOrderSignal
      );
      return workOrder ? new WorkOrderObject(workOrder) : null;
    } catch (err) {
      throw err;
    }
  };

  getWorkOrderLinesByWorkOrderId = async (workOrderId) => {
    try {
      if (!workOrderId) {
        throw new Error('Invalid Id');
      }

      this.workOrderLinesAbortController.abort();

      this.workOrderLinesAbortController = new AbortController();
      this.workOrderLineSignal = this.workOrderLinesAbortController.signal;

      const workOrderLines = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/work-order/${workOrderId}/work-order-line`,
        'GET',
        null,
        this.workOrderLineSignal
      );
      return workOrderLines;
    } catch (err) {
      throw err;
    }
  };

  WorkOrderObject(props) {
    return new WorkOrderObject(props);
  }

  createWorkOrderOnCustomEndpoint = async (data) => {
    try {
      this.workOrderAbortController.abort();

      this.workOrderAbortController = new AbortController();
      this.workOrderSignal = this.workOrderAbortController.signal;

      const workOrder = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/work-order`,
        'POST',
        data,
        this.workOrderSignal
      );
      const workOrderObject = new WorkOrderObject(workOrder);
      this.data.set(workOrderObject.id, workOrderObject);
      return workOrderObject;
    } catch (err) {
      throw err;
    }
  };

  createWorkOrderOnTMTCustomEndpoint = async (data) => {
    try {
      this.workOrderAbortController.abort();

      this.workOrderAbortController = new AbortController();
      this.workOrderSignal = this.workOrderAbortController.signal;

      const workOrder = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/work-order-tmt`,
        'POST',
        data,
        this.workOrderSignal
      );
      const workOrderObject = new WorkOrderObject(workOrder);
      return workOrderObject;
    } catch (err) {
      throw err;
    }
  };

  getCarShopTMTInfo = async (filters = {}) => {
    try {
      this.searchWorkOrdersAbortController.abort();

      this.searchWorkOrdersAbortController = new AbortController();

      // query string filters
      const queryString = new URLSearchParams(filters).toString();

      const data = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/car-work-order-shop/search?${queryString}`,
        'GET',
        null,
        this.searchWorkOrdersAbortController.signal
      );

      return data;
    } catch (err) {
      throw err;
    }
  };

  getTMTComponentCodes = async (filters = {}) => {
    try {
      this.searchWorkOrdersAbortController.abort();

      this.searchWorkOrdersAbortController = new AbortController();

      // query string filters
      const queryString = new URLSearchParams(filters).toString();

      const data = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/component-code/search?${queryString}`,
        'GET',
        null,
        this.searchWorkOrdersAbortController.signal
      );

      return data;
    } catch (err) {
      throw err;
    }
  };

  getTMTComplaintCodes = async () => {
    try {
      this.searchWorkOrdersAbortController.abort();

      this.searchWorkOrdersAbortController = new AbortController();

      const data = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/complaint-code`,
        'GET',
        null,
        this.searchWorkOrdersAbortController.signal
      );

      return data;
    } catch (err) {
      throw err;
    }
  };

  getTMTRepairCodes = async () => {
    try {
      this.searchWorkOrdersAbortController.abort();

      this.searchWorkOrdersAbortController = new AbortController();

      const data = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/repair-code`,
        'GET',
        null,
        this.searchWorkOrdersAbortController.signal
      );

      return data;
    } catch (err) {
      throw err;
    }
  };

  updateWorkOrderOnCustomEndpoint = async ({ id, ...data }) => {
    try {
      this.workOrderAbortController.abort();

      this.workOrderAbortController = new AbortController();
      this.workOrderSignal = this.workOrderAbortController.signal;

      const workOrder = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/work-order/${id}`,
        'PATCH',
        data,
        this.workOrderSignal
      );
      const workOrderObject = new WorkOrderObject(workOrder);
      this.data.set(workOrderObject.id, workOrderObject);
      return workOrderObject;
    } catch (err) {
      throw err;
    }
  };

  getShopParts = async (shopId, filters = {}) => {
    try {
      if (!shopId) {
        throw new Error('Invalid Id');
      }

      try {
        if (this.partsSignal) {
          this.partsAbortController.abort();
        }
      } catch (error) {
        Logger.error('Error aborting parts signal', error);
      }

      this.partsAbortController = new AbortController();
      this.partsSignal = this.partsAbortController.signal;

      const shopSubfleetEcosystem = await ShopStore.getShopSubfleetEcosystem(
        shopId
      );
      // remove the empty ones
      const shopIds =
        shopSubfleetEcosystem && shopSubfleetEcosystem.data
          ? shopSubfleetEcosystem.data
              .filter((shop) => !_.isEmpty(shop) && shop.id)
              .map((shop) => shop.id)
              .join(',')
          : shopId;

      if (shopId === '-1' || shopId === -1) {
        return [];
      }

      let filtersString = '';
      if (filters && !_.isEmpty(filters) && _.isObject(filters)) {
        filtersString = Object.keys(filters)
          .filter(
            (key) =>
              filters[key] !== undefined &&
              filters[key] !== null &&
              filters[key] !== ''
          )
          .map((key) => `${key}=${filters[key]}`)
          .join('&');
      }

      const parts = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/vehicle-part/search?shopIds=${shopIds}${
          filtersString ? `&${filtersString}` : ''
        }`,
        'GET',
        null,
        this.partsSignal
      );
      return parts;
    } catch (err) {
      throw err;
    }
  };

  getShopPartReports = async (shopId) => {
    try {
      if (!shopId) {
        throw new Error('Invalid Id');
      }

      if (shopId === '-1' || shopId === -1) {
        return {};
      }

      const shopSubfleetEcosystem = await ShopStore.getShopSubfleetEcosystem(
        shopId
      );
      // remove the empty ones
      const shopIds =
        shopSubfleetEcosystem && shopSubfleetEcosystem.data
          ? shopSubfleetEcosystem.data
              .filter((shop) => !_.isEmpty(shop) && shop.id)
              .map((shop) => shop.id)
              .join(',')
          : shopId;

      const reports = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/vehicle-part/report?shopIds=${shopIds}`,
        'GET',
        null,
        this.partsSignal
      );
      return reports;
    } catch (err) {
      throw err;
    }
  };

  createPartOnCustomEndpoint = async (data) => {
    try {
      this.partsAbortController.abort();

      this.partsAbortController = new AbortController();
      this.partsSignal = this.partsAbortController.signal;

      const part = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/vehicle-part`,
        'POST',
        data,
        this.partsSignal
      );
      return part;
    } catch (err) {
      throw err;
    }
  };

  /**
   *
   * @param {*} part
   * @returns
   */
  updatePartUnitCost = async ({ id_vehicle_part, cost }) => {
    try {
      this.updatePartsAbortController.abort();

      this.updatePartsAbortController = new AbortController();

      const partResponse = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/vehicle-part/${id_vehicle_part}`,
        'PATCH',
        {
          cost,
        },
        this.updatePartsAbortController.signal
      );
      return partResponse;
    } catch (err) {
      throw err;
    }
  };

  /**
   *
   * @param {*} part
   * @returns
   */
  updatePartStock = async ({ id_vehicle_part, amount, originalAmount }) => {
    try {
      this.updatePartsAbortController.abort();

      this.updatePartsAbortController = new AbortController();

      const partResponse = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/vehicle-part/${id_vehicle_part}`,
        'PATCH',
        {
          amountToSubtract: amount,
          amountToSum: originalAmount,
        },
        this.updatePartsAbortController.signal
      );
      return partResponse;
    } catch (err) {
      throw err;
    }
  };

  updatePartOnCustomEndpoint = async ({ id, ...data }) => {
    try {
      this.updatePartsAbortController.abort();

      this.updatePartsAbortController = new AbortController();

      const partResponse = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/vehicle-part/${id}`,
        'PATCH',
        data,
        this.updatePartsAbortController.signal
      );
      return partResponse;
    } catch (err) {
      throw err;
    }
  };

  deletePartOnCustomEndpoint = async (id) => {
    try {
      this.partsAbortController.abort();

      this.partsAbortController = new AbortController();
      this.partsSignal = this.partsAbortController.signal;

      const part = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/vehicle-part/${id}`,
        'DELETE',
        {
          id,
        },
        this.partsSignal
      );
      return part;
    } catch (err) {
      throw err;
    }
  };

  deleteWorkOrder = async (id) => {
    try {
      this.workOrderAbortController.abort();

      this.workOrderAbortController = new AbortController();
      this.workOrderSignal = this.workOrderAbortController.signal;

      const workOrder = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/work-order/${id}`,
        'DELETE',
        {
          id,
        },
        this.workOrderSignal
      );
      return workOrder;
    } catch (err) {
      throw err;
    }
  };

  saveWorkOrderLinesOnCustomEndpoint = async (workOrderId, payload) => {
    try {
      this.workOrderAbortController.abort();

      this.workOrderAbortController = new AbortController();
      this.workOrderSignal = this.workOrderAbortController.signal;

      const workOrderLinesResponse = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/work-order/${workOrderId}/work-order-line`,
        'POST',
        payload,
        this.workOrderSignal
      );
      // const workOrderObject = new WorkOrderObject(workOrder);
      // this.data.set(workOrderObject.id, workOrderObject);
      return workOrderLinesResponse;
    } catch (err) {
      throw err;
    }
  };

  createWorkOrders = async (workOrders) => {
    const result = await Promise.all(workOrders.map(this.createWorkOrder));
    return result;
  };

  createWorkOrder = async (data) => {
    try {
      const workOrder = await webServiceProvider.post('workOrder', data);
      const workOrderObject = new WorkOrderObject(workOrder);
      this.data.set(workOrderObject.id, workOrderObject);
    } catch (err) {
      throw err;
    }
  };

  addWorkOrderToStore = (workOrderId, workOrder, isReformated) => {
    if (!this.data.has(Number(workOrderId)) || isReformated) {
      this.data.set(
        Number(workOrderId),
        workOrder instanceof WorkOrderObject
          ? workOrder
          : new WorkOrderObject(workOrder)
      );
    }
  };

  shopWorkOrdersCache = {};

  clearShopWorkOrdersCache = () => {
    this.shopWorkOrdersCache = {};
  };

  fetchShopWorkOrders = async (shopId = ShopStore.currentShop.id) => {
    try {
      if (this.shopWorkOrdersCache[shopId]) {
        return this.shopWorkOrdersCache[shopId];
      }

      this.shopWorkOrdersAbortController.abort();

      this.shopWorkOrdersAbortController = new AbortController();

      const data = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/work-order?id_shop=${shopId}`,
        'GET',
        null,
        this.shopWorkOrdersAbortController.signal
      );

      // This is temporary until we have a better way to handle this
      const workOrderLinesRequests = [];
      for (const workOrder of data) {
        // populate work order with workOrder lines
        workOrderLinesRequests.push(
          webServiceProvider.directFetchWithMethod(
            `${DIRECT_ENDPOINT}/work-order/${workOrder.id}`,
            'GET',
            null
          )
        );
      }

      const workOrdersDetails = await Promise.all(workOrderLinesRequests);

      this.shopWorkOrdersCache[shopId] = [...workOrdersDetails];

      return data;
    } catch (e) {
      console.error(e);
    }
  };

  searchWorkOrders = async (filters = {}) => {
    try {
      this.searchWorkOrdersAbortController.abort();

      this.searchWorkOrdersAbortController = new AbortController();

      // query string filters
      const queryString = new URLSearchParams(filters).toString();

      const data = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/work-order/search?${queryString}`,
        'GET',
        null,
        this.searchWorkOrdersAbortController.signal
      );

      return data;
    } catch (e) {
      console.error(e);
    }
  };

  searchWorkOrdersDvir = async (filters = {}) => {
    try {
      this.searchWorkOrdersAbortController.abort();

      this.searchWorkOrdersAbortController = new AbortController();

      // query string filters
      const queryString = new URLSearchParams(filters).toString();

      const data = await webServiceProvider.directFetchWithMethod(
        `${DIRECT_ENDPOINT}/work-order-dvir/search?${queryString}`,
        'GET',
        null,
        this.searchWorkOrdersAbortController.signal
      );

      return data;
    } catch (e) {
      console.error(e);
    }
  };

  fetchWorkOrders = async (filters = {}) => {
    this.fetchWorkOrdersAbortController.abort();

    this.fetchWorkOrdersAbortController = new AbortController();

    // query string filters
    const queryString = new URLSearchParams(filters).toString();

    const data = await webServiceProvider.directFetchWithMethod(
      `${DIRECT_ENDPOINT}/work-order?${queryString}`,
      'GET',
      null,
      this.fetchWorkOrdersAbortController.signal
    );

    return data;
  };

  fetchWorkOrdersForTable = async (
    { offset, limit, filter, sort } = {},
    tableStore = new TableStore(),
    shopId = ShopStore.currentShop.id,
    hydrateWithCarDetails = false
  ) => {
    if (tableStore.pending) {
      return;
    }
    tableStore.pending = true;
    tableStore.loaded = false;
    offset = offset === undefined ? tableStore.offset : offset;
    limit = limit === undefined ? tableStore.limit : limit;
    sort = sort === undefined ? tableStore.sort : sort;
    if (shopId === -1 && CurrentUserStore.user.role === 'admin') {
      shopId = undefined;
    }
    if (!shopId) {
      return;
    }
    try {
      this.workOrderAbortController.abort();

      this.workOrderAbortController = new AbortController();
      this.workOrderSignal = this.workOrderAbortController.signal;

      let queryString = `${DIRECT_ENDPOINT}/work-order?id_shop=${shopId}`;
      if (sort) {
        queryString += `&sort=${sort}`;
      }
      if (filter) {
        const filters = Object.keys(filter).map(
          (key) => `${key}=${filter[key]}`
        );
        queryString += `&${filters.join('&')}`;
      }
      if (offset) {
        queryString += `&offset=${offset}`;
      } else {
        queryString += '&offset=0';
      }
      if (limit) {
        queryString += `&limit=${limit}`;
      } else {
        queryString += '&limit=999';
      }
      let { data, meta } = await webServiceProvider.directFetchWithMethod(
        queryString,
        'GET',
        null,
        this.workOrderSignal
      );

      // hydrate items with car_details
      if (hydrateWithCarDetails && data && !_.isEmpty(data)) {
        const carDetails = await this.getCarDetails(
          _.uniq(data.map((d) => d.id_car))
        );
        data = data.map((workOrder) => {
          return {
            ...workOrder,
            car_details: carDetails.find(
              (car) => car.id === workOrder.id_car
            ) || {
              notFound: true,
            },
          };
        });
      }

      transaction(() => {
        tableStore.data = [];
        for (let i = 0; i < data.length; i++) {
          const workOrderObj = new WorkOrderObject(data[i]);
          this.data.set(Number(workOrderObj.id), workOrderObj);
          tableStore.data.push(Number(workOrderObj.id));
        }
        // Change length based on server total response
        // tableStore.setDataMetadata(data.length, offset, limit, sort, filter);
        tableStore.setDataMetadata(
          meta.total,
          meta.pagination.offset,
          meta.pagination.limit,
          sort,
          filter
        );
      });
    } catch (e) {
      tableStore.data = [];
      tableStore.setDataMetadata(0, offset, limit, sort, filter);
      // tableStore.setError('Unable to load services.');
    }
  };

  /**
   *
   * @param {String} carId - comma separated car ids
   * @returns
   */
  async getCarDetails(carIds) {
    if (!carIds) {
      throw new Error('carIds not set');
    }

    try {
      const response = await CarStore.getCarsBasicInfoAndReturn(carIds);

      return response;
    } catch (err) {
      console.error(err);
    }
  }

  async printWorkOrder(workOrderId) {
    try {
      let data = await webServiceProvider.getPDF(
        `v1/work-order/${workOrderId}/pdf`
      );
      print(data, `${workOrderId} - Work Order`, 'application/pdf');
    } catch (err) {
      console.error(err);
    }
  }

  async downloadWorkOrder(workOrderId) {
    try {
      let data = await webServiceProvider.getPDF(
        `v1/work-order/${workOrderId}/pdf`
      );
      download(data, `${workOrderId} - Work Order.pdf`, 'application/pdf');
    } catch (err) {
      console.error(err);
    }
  }

  async workOrdersGroupedByStatusAndAssignedUser(shopId) {
    try {
      const data = await webServiceProvider.get(
        `v1/work-order/fleet/${shopId}/group-by-status-and-count`
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  setWorkOrderBeingCreatedField = (workOrderField, value) => {
    this.workOrderBeingSaved[workOrderField] = value;
  };

  setWorkOrderInitialValues = () => {
    this.workOrderBeingSaved = JSON.parse(
      JSON.stringify(this.workOrderInitialValues)
    );
  };

  workOrderImgOcr = async (formData, signal) => {
    const data = await webServiceProvider.post(
      'v1/work-order/img-ocr',
      formData,
      signal
    );
    return data;
  };

  searchVmrs = async ({
    value,
    type,
  }) => {
    const data = await webServiceProvider.directFetchWithMethod(
      `${DIRECT_ENDPOINT}/vmrs?search=${value}&type=${type}`,
      'GET',
      null
    );

    return data;
  };

  getAsmCodesForSysCode = async (vmrsKeyId) => {
    const data = await webServiceProvider.directFetchWithMethod(
      `${DIRECT_ENDPOINT}/vmrs/asm-code?vmrsKeyId=${vmrsKeyId}`,
      'GET',
      null
    );

    return data;
  }

  getVmrsCodeById = async (vmrsKeyId) => {
    const data = await webServiceProvider.directFetchWithMethod(
      `${DIRECT_ENDPOINT}/vmrs/${vmrsKeyId}`,
      'GET',
      null
    );

    return data;
  }

  getWorkOrderSamsaraUsersByShopId = async (shopId) => {
    const data = await webServiceProvider.directFetchWithMethod(
      `${DIRECT_ENDPOINT}/work-order/samsara-users?shopId=${shopId}`,
      'GET',
      null
    );

    return data;
  }
}

decorate(WorkOrderStore, {
  workOrderBeingSaved: observable,
  selectedCarIssues: observable,
  setSelectedCarIssues: action,
  formatWorkOrderLines: action,
  setWorkOrderBeingCreatedField: action,
  setWorkOrderInitialValues: action,
  addWorkOrderToStore: action,
  fetchShopWorkOrders: action,
  clearShopWorkOrdersCache: action,
  searchWorkOrders: action,
});

var WOStore = new WorkOrderStore();
export default WOStore;
